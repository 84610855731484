<template>
  <div class="table-box">
    <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
  export default {
    data() {
      let _this = this;
      return {
        opt: {
          search: [{
              key: "date",
              label: "起止日期",
              timeType: "yyyy-MM-dd",
              format: "yyyy-MM-dd",
              type: "daterange"
            },
            {
              key: "actionType",
              label: "常开类型",
              type: "select",
              opt: {
                list: [{
                label: "单次常开",
                value: "onceLongOpen"
              },
              {
                label: "周期常开",
                value: "periodLongOpen"
              },
              {
                label: "上锁/闭锁",
                value: "closeLock"
              }],
              }
            }
          ],
          columns: [{
              label: "序号",
              key: "sequenceNumber",
              width: 80
            },
            {
              label: "常开类型",
              key: "actionType"
            },
            {
              label: "操作配置",
              key: "actionData"
            },
            {
              label: "操作人角色",
              key: "userRole"
            },
            {
              label: "操作人姓名",
              key: "userName"
            },
           {
              label: "操作时间",
              key: "createTime"
            },
          ],
        },
      };
    },
    created() {
    },
    activated() {
    },
    mounted() {
    },
    methods: {
      onGet(opt) {
        console.log(opt.searchForm);
        let deviceId = this.$route.params.deviceId;
        console.log('deviceId:',deviceId);
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          startTime: opt.searchForm["date"] ? opt.searchForm["date"][0] : "",
          endTime: opt.searchForm["date"] ? opt.searchForm["date"][1] : "",
          actionType: opt.searchForm["actionType"] ? opt.searchForm["actionType"] : null,
          deviceId: deviceId,
        };
        this.post("/device-service/device/audit/log/v1/long-open-setting/query", dto, {
          isUseResponse: true
        }).then(res => {
          if(res.data.code == 0) {
            let data = res.data.data;
            data.data.forEach((item,index) => {
              item.sequenceNumber = opt.limit * (opt.skip - 1) + index + 1;
              item.actionData = item.actionData || '--';
            });
            opt.cb(data);
          }
        });
      },
    }
  };
</script>
<style lang="scss" scoped>
  .table-box {
    height: 620px;
  }
</style>